<template>
         <div id="faqAccordion" class="container-fluid">
            <b-row >
                <b-col lg="12">
                    <div class="iq-accordion career-style faq-style">
                        <card class=" iq-accordion-block">
                            <div class="active-faq clearfix" id="headingOne">
                                <div class="container-fluid">
                                    <b-row >
                                        <b-col sm="12" >
                                            <a role="contentinfo" class="accordion-title" data-toggle="collapse"
                                                data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                <span>Lorem ipsum dolor sit </span>
                                            </a>
                                        </b-col>
                                    </b-row>
                                </div>
                            </div>
                            <div class="accordion-details collapse show" id="collapseOne" aria-labelledby="headingOne"
                                data-parent="#faqAccordion">
                                <p class="mb-0">Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia
                                aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt
                                aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft
                                beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat
                                craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore
                                sustainable VHS. </p>
                            </div>
                        </card>
                        <card class=" iq-accordion-block">
                            <div class="active-faq clearfix" id="headingTwo">
                                <div class="container-fluid">
                                    <b-row>
                                        <b-col sm="12"><a role="contentinfo" class="accordion-title collapsed"
                                                data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false"
                                                aria-controls="collapseTwo"><span> consectetur adipiscing elit
                                                </span> </a></b-col>
                                    </b-row>
                                </div>
                            </div>
                            <div class="accordion-details collapse" id="collapseTwo" aria-labelledby="headingTwo"
                                data-parent="#faqAccordion">
                                <p class="mb-0">Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia
                                aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt
                                aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft
                                beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat
                                craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore
                                sustainable VHS.
                                </p>
                            </div>
                        </card>
                        <card class=" iq-accordion-block ">
                            <div class="active-faq clearfix" id="headingThree">
                                <div class="container-fluid">
                                    <b-row>
                                        <b-col sm="12" ><a role="contentinfo" class="accordion-title collapsed"
                                                data-toggle="collapse" data-target="#collapseThree" aria-expanded="false"
                                                aria-controls="collapseThree"><span>Etiam sit amet justo non </span> </a>
                                        </b-col>
                                    </b-row>
                                </div>
                            </div>
                            <div class="accordion-details collapse" id="collapseThree" aria-labelledby="headingThree"
                                data-parent="#faqAccordion">
                                <p class="mb-0">Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia
                                aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt
                                aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft
                                beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat
                                craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore
                                sustainable VHS.
                                </p>
                            </div>
                        </card>
                        <card class=" iq-accordion-block ">
                            <div class="active-faq clearfix" id="headingFour">
                                <div class="container-fluid">
                                    <b-row>
                                        <b-col sm="12"><a role="contentinfo" class="accordion-title collapsed"
                                                data-toggle="collapse" data-target="#collapseFour" aria-expanded="false"
                                                aria-controls="collapseFour"><span> velit accumsan laoreet </span> </a>
                                        </b-col>
                                    </b-row>
                                </div>
                            </div>
                            <div class="accordion-details collapse" id="collapseFour" aria-labelledby="headingFour"
                                data-parent="#faqAccordion">
                                <p class="mb-0">Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia
                                aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt
                                aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft
                                beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat
                                craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore
                                sustainable VHS.
                                </p>
                            </div>
                        </card>
                        <card class=" iq-accordion-block">
                            <div class="active-faq clearfix" id="headingFive">
                                <div class="container-fluid">
                                    <b-row>
                                        <b-col sm="12"><a role="contentinfo" class="accordion-title collapsed"
                                                data-toggle="collapse" data-target="#collapseFive" aria-expanded="false"
                                                aria-controls="collapseFive"><span> Donec volutpat metus in erat </span> </a></b-col>
                                    </b-row>
                                </div>
                            </div>
                            <div class="accordion-details collapse" id="collapseFive" aria-labelledby="headingFive"
                                data-parent="#faqAccordion">
                                <p class="mb-0">Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia
                                aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt
                                aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft
                                beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat
                                craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore
                                sustainable VHS.
                                </p>
                            </div>
                        </card>
                        <card class=" iq-accordion-block">
                            <div class="active-faq clearfix" id="headingSix">
                                <div class="container-fluid">
                                    <b-row>
                                        <b-col sm="12"><a role="contentinfo" class="accordion-title collapsed"
                                                data-toggle="collapse" data-target="#collapseSix" aria-expanded="false"
                                                aria-controls="collapseSix"><span> quam quis massa tristique </span> </a>
                                        </b-col>
                                    </b-row>
                                </div>
                            </div>
                            <div class="accordion-details collapse" id="collapseSix" aria-labelledby="headingSix"
                                data-parent="#faqAccordion">
                                <p class="mb-0">>Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia
                                aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt
                                aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft
                                beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat
                                craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore
                                sustainable VHS.
                                </p>
                            </div>
                        </card>
                    </div>
                </b-col>
            </b-row>
        </div>
</template>
<script>
export default {
    name:"Termsofuse"
}
</script>